import PeriodsDropdown from '../Components/Dropdowns/PeriodsDropdown';
import React, {useState} from 'react';
import {useQuery} from '@apollo/client';
import {currentPeriodQuery} from '../../queries/currentPeriodQuery';
import {toastError, toastSuccess} from '../../utils/toast';
import {toast} from 'react-toastify';
import Page from '../Components/Page';
import {invokeDAGviaApiPost} from '../../Api/invokeDag';


export const ExtractTriggerCleanseDataCsv = () => {
    const currentPeriod = useQuery(currentPeriodQuery);
    const dagToTrigger = "cleansed_data_csv_extract";
    const [selectedPeriod, updateSelectedPeriod] = useState(undefined);
    selectedPeriod === undefined && currentPeriod?.data && updateSelectedPeriod(currentPeriod.data?.records[0]?.periodAbbreviation)

    function triggerDag() {
        invokeDAGviaApiPost(dagToTrigger, {period_abbreviation: selectedPeriod})
            .then((response) => {
                if (response?.apiError)
                    toastError(toast, `${dagToTrigger} DAG trigger with ${selectedPeriod} failed with error ${JSON.stringify(response.apiError)}`)
                else
                    toastSuccess(toast, `${dagToTrigger} DAG triggered successfully with ${selectedPeriod}: response ${JSON.stringify(response)}`)
            })
            .catch(e => {
                toastError(toast, `${dagToTrigger} DAG trigger with ${selectedPeriod} failed with error ${JSON.stringify(e)}`)
            })
    }

    return <Page name={"Cleanse Data CSV Extract"}>
        <div className={'cleanse-data-csv-extract'}>
            <PeriodsDropdown
                id={'period'}
                className={"data-row-dropdown"}
                updatePeriod={(_, period) => {
                    updateSelectedPeriod(period.periodAbbreviation)
                }}
                selectedPeriod={selectedPeriod}
                graphqlQuery={'{ periodList:periodDetails {periodAbbreviation isCurrentPeriod } }'}
                periodsFunction={(result) => result.periodList}
                showLabel
            />

            <div className={'dag-trigger-group'}>
                <button type="button"
                        className="button primary submit"
                        onClick={() => triggerDag()}
                >
                    Submit
                </button>
            </div>
        </div>
        <div><a className={'data-location'} href={process.env.REACT_APP_EXTRACTS_CSV_URL}>
            File Output Location
        </a></div>
    </Page>

}


