import React, {useState} from "react";
import NameValueLabels from "../../../Components/NameValueLabels";
import cleanseIcon from "../../../../assets/cleanse.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFillDrip, faStoreSlash, faWater} from "@fortawesome/free-solid-svg-icons";
import {faProductHunt} from "@fortawesome/free-brands-svg-icons";

const StoreTotalsDollarsKey = () => {
    const [inHover, setHover] = useState(false);
    return <div data-testid={"tooltip-icon"}
                className="fas fa-key tooltip-icon" onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
        {inHover && <div className={'tooltip-contents'}>
            <NameValueLabels
                containerClassName={'total-dollars-key-list'}
                labelValuePairs={[
                    {label: "Cleanse:", value: <img alt={'cleanse'} className={'cleanse-icon'} src={cleanseIcon}/>},
                    {label: "Fill:", value: <FontAwesomeIcon id={"fill"}  icon={faFillDrip}/>},
                    {label: "Do Not Fill:", value: <FontAwesomeIcon id={"do_not_fill"} icon={faFillDrip} className={"do-not-fill-color"}/>},
                    {label: "Always Fill:", value: <FontAwesomeIcon id={"always_fill"} icon={faFillDrip} className={"always-fill-color"}/>},
                    {label: "Store Closed (Ops Portal):", value: <FontAwesomeIcon id={"store_closed_(ops_portal)"} icon={faStoreSlash} className={'store-closed-color'}/>},
                    {label: "Store Closure:", value: <FontAwesomeIcon id={"store_closure"} icon={faStoreSlash} className={'store-closure-color'}/>},
                    {label: "Measures Start:", value: <div data-testId={"measures_start"} className={'store-date-start border-key'}>{"Data"}</div>},
                    {label: "Measures Stop:", value: <div data-testId={"measures_stop"} className={'store-date-stop border-key'}>{"Data"}</div>},
                    {label: "Not Outlier:", value: <div data-testId={"not_outlier"} className={'not-outlier border-key'}>{"Data"}</div>},
                    {label: "Small Outlier:", value: <div data-testId={"small_outlier"} className={'outlier-severity-1 border-key'}>{"Data"}</div>},
                    {label: "Medium Outlier:", value: <div data-testId={"medium_outlier"} className={'outlier-severity-2 border-key'}>{"Data"}</div>},
                    {label: "Large Outlier:", value: <div data-testId={"large_outlier"} className={'outlier-severity-3 border-key'}>{"Data"}</div>},
                    {label: "XL Outlier:", value: <div data-testId={"xl_outlier"} className={'outlier-severity-4 border-key'}>{"Data"}</div>},
                    {label: "Fill Watermark:", value: <FontAwesomeIcon id={"fill_watermark"} icon={faWater} shake />},
                    {label: "Promo Watermark:", value: <FontAwesomeIcon id={"promo_watermark"} icon={faProductHunt} shake />}
                ]}/>
        </div>}
    </div>
}

export default StoreTotalsDollarsKey