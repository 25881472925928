import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {getOpsAlertColumns} from "./opsAlertsContentConstants";
import ReactTable from "../Components/ReactTable";
import _ from "lodash";
import {getMoment} from "../../utils/time";
import {getStringParamOrDefault} from "../../utils/urlBuilder";
import ShowAcknowledgedAlertsCheckbox from "./ShowAcknowledgedAlertsCheckbox";
import DataIssueFilterCheckboxes from "./DataIssueFilterCheckboxes";
import {AcknowledgeAll} from "./AcknowledgeAll";
import JiraLoginButton from "./JiraLoginButton";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import {makeApiCall} from "../../Api/makeApiCall";

export const isAlertTaskLinkable = (task) => {
    switch (task) {
        case 'all_records_rejected':
        case 'only_unmapped_stores_found':
            return true

        default:
            return false
    }
}

const OpsAlertsContent = ({
                                 data,
                                 url,
                                 showAcknowledgedAlertsProps,
                                 showJiraLoginButton,
                                 showAcknowledgedAlertsButton
                             }) => {

    const [initialData, setInitialData] = useState(data);
    const [metadata, setMetadata] = useState([]);
    const [taskTypes, setTaskTypes] = useState([]);


    const [showDataIssue, setShowDataIssue] = useState(false);
    const [showNotDataIssue, setShowNotDataIssue] = useState(false);
    const [showFurtherReview, setShowFurtherReview] = useState(false);

    const [filteredData, setFilteredData] = useState([]);
    const [enrichingData, setEnrichingData] = useState(true);
    const [filteringData, setFilteringData] = useState(true);

    const {updateShowAcknowledgedAlerts, showAcknowledgedAlerts} = showAcknowledgedAlertsProps;

    const parsePayload = data => {
        try {
            let result = JSON.parse(data.payload);
            if (Array.isArray(result) && data.task_name === 'retailers_always_fill_failed') {
                return {"retailerIds": [...new Set(result.map(t => t.retailerId))]}
            }
            return result;
        } catch (e) {
            return data;
        }
    };

    const filterAlertData = data => data.filter(record => {
        let keepRecord = true
        if (showAcknowledgedAlerts === true && record.acknowledged === true) {
            const recordFalsePositive = record.falsePositive;
            const isDataIssue = showDataIssue === true && recordFalsePositive === false;
            const isNotDataIssue = showNotDataIssue === true && recordFalsePositive === true;
            const isFalsePositive = showFurtherReview === true && recordFalsePositive === null;
            const noFilter = (!showDataIssue && !showNotDataIssue && !showFurtherReview)
            keepRecord = (isDataIssue || isNotDataIssue || isFalsePositive || noFilter)
        }
        return keepRecord
    });

    const getDataIssueClassName = rowInfo => {
        if (rowInfo?.original.acknowledged === true) {
            if (rowInfo?.original.falsePositive === false) return 'data-issue-border'
            if (rowInfo?.original.falsePositive === true) return 'no-data-issue-border'
            if (rowInfo?.original.falsePositive === null) return 'further-review-border'
        }
        return ''
    };

    const updateAlertData = () => {
        setFilteringData(true);
        makeApiCall(url).then(data => setInitialData(data))
    };

    const updateShowDataIssue = showDataIssue => setShowDataIssue(showDataIssue);

    const updateNotDataIssue = notDataIssue => setShowNotDataIssue(notDataIssue);

    const updateFurtherReview = furtherReview => setShowFurtherReview(furtherReview);

    const getFilters = () => {
        // if trying to refactor, see commit first
        return [
            {
                id: "retailer_id",
                value: getStringParamOrDefault('retailerId', '')
            },
            {
                id: "file",
                value: getStringParamOrDefault('file', '')
            },
            {
                id: "timestamp",
                value: getStringParamOrDefault('timestamp', '')
            },
            {
                id: "task_name",
                value: getStringParamOrDefault('task_name', '')
            }
        ];
    };

    useEffect(() => {
        const filteringData = async () => {
            const promises = initialData.metadata.map((data) => {
                const filteredAlert = _.filter(initialData.taskTypes, {alertId: data.task_name})
                const displayName = filteredAlert.length > 0 ? filteredAlert[0].displayName : data.task_name
                return ({
                    ...data,
                    task_name: displayName,
                    timestamp: getMoment(data.timestamp).format('YYYY-MM-DDThh:mm:ss.SSS Z'),
                    updateAlertDataFunction: updateAlertData,
                    ...(parsePayload(data))
                });
            });

            await Promise.all(promises).then((data) => {
                setTaskTypes(initialData.taskTypes);
                setMetadata(data);
            });
        };

        setEnrichingData(true);
        filteringData().finally(() => setEnrichingData(false));

    }, [initialData]);

    useEffect(() => {
        const filteringData = async () => {
            await Promise.all(
                filterAlertData(metadata)
            ).then((filter) => setFilteredData(filter));
        }
        if (!_.isEmpty(metadata)) {
            setFilteringData(true);
            filteringData().finally(() => setFilteringData(false));
        }
        else {
            setFilteringData(false);
        }

    }, [metadata, showDataIssue, showNotDataIssue, showFurtherReview])

    return (<div className={'ops-alerts-content'}>
        <LoadingWrapper loading={enrichingData || filteringData}>
            <ReactTable
                filterable
                initialState={{columnFilters: getFilters()}}
                data={filteredData}
                columns={getOpsAlertColumns(taskTypes.map(tt => tt.displayName))}
                getTrProps={(rowInfo) => {
                    const alertAcknowledgeClassName = rowInfo?.original?.acknowledged === true ? 'alert-acknowledged' : '';
                    const dataIssueClassName = getDataIssueClassName(rowInfo);
                    const className = `${alertAcknowledgeClassName} ${dataIssueClassName}`.trim();
                    return {className};
                }}
            >
                {({rows}) => {
                    return (
                        <div className={'alerts-selections'}>
                            {showAcknowledgedAlertsButton === true && <ShowAcknowledgedAlertsCheckbox
                                updateShowAcknowledgedAlerts={updateShowAcknowledgedAlerts}
                                showAcknowledgedAlerts={showAcknowledgedAlerts}
                            />}
                            {showAcknowledgedAlerts && <DataIssueFilterCheckboxes
                                updateShowDataIssueAlerts={updateShowDataIssue}
                                showDataIssueAlerts={showDataIssue}
                                updateNotDataIssueAlerts={updateNotDataIssue}
                                notDataIssueAlerts={showNotDataIssue}
                                updateFurtherReviewAlerts={updateFurtherReview}
                                furtherReviewAlerts={showFurtherReview}
                            />}
                            <div data-testid={'alerts-divider'}
                                 className={showAcknowledgedAlerts ? 'alerts-divider' : ''}>
                                <AcknowledgeAll data={rows} onSubmitCallback={updateAlertData}/>
                            </div>
                            {showJiraLoginButton && <JiraLoginButton/>}
                        </div>
                    )
                }}
            </ReactTable>
        </LoadingWrapper>
    </div>);
};

OpsAlertsContent.defaultProps = {
    showAcknowledgedAlertsButton: false,
    showJiraLoginButton: false,
    showAcknowledgedAlertsProps: {showAcknowledgedAlerts: false, updateShowAcknowledgedAlerts: () => null}
};

OpsAlertsContent.propTypes = {
    data: PropTypes.shape({
        metadata: PropTypes.array,
        taskTypes: PropTypes.arrayOf(PropTypes.object)
    }),
    showAcknowledgedAlertsButton: PropTypes.bool,
    showAcknowledgedAlertsProps: PropTypes.object,
    showJiraLoginButton: PropTypes.bool,
    url: PropTypes.string
};

export default OpsAlertsContent;