export const checkApiResults = (results, status) => {
    if (status !== window.google.maps.GeocoderStatus.OK) {
        console.log("=============> api call failed: ", status)
        return undefined
    }
    if (results?.length === 0) {
        console.log("=============> api call returned no results")
        return undefined
    }
    return results
}
