import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash'
import LoadingSpinner from '../Dashboard/Components/LoadingSpinner';
import {makeApiCall} from './makeApiCall';
import ErrorComponent from '../Dashboard/Components/ErrorComponent';
import {makeApolloClientCall} from './makeApolloApiCall';
import 'react-table-hoc-fixed-columns/lib/styles.css'
import {v4 as uuidv4} from 'uuid';
import ReactTable from "../Dashboard/Components/ReactTable";


const COMPONENT_STATUS = {
    LOADING: 'loading',
    COMPLETED: 'completed',
    ERRORED: 'errored',
    UPDATING: 'updating'
};

class ApiGetContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: undefined,
            results: [],
            status: COMPONENT_STATUS.LOADING,
            showPagination: false,
            uuid: undefined
        };

        this.callApi = this.callApi.bind(this);
        this.callRestApi = this.callRestApi.bind(this);
        this.callApolloClient = this.callApolloClient.bind(this);
    }

    reload(status) {
        const uuid = uuidv4();
        this.setState({
                status: status,
                uuid
            },
            this.callApi
        );

    }

    callApi = () => {
        const myUuid = this.state.uuid;
        const apiCall = this.props.graphqlQuery ? this.callApolloClient(this.props.graphqlQuery) : this.callRestApi()
        return apiCall
            .then((results) => {
                if(myUuid === this.state.uuid)
                    this.setData(results);
            })
            .catch((error) => {
                if(myUuid === this.state.uuid)
                this.setError(`An error occurred during the query: ${error.message}`)
            });
    };

    callApolloClient = (graphqlQuery) => {
        return makeApolloClientCall(graphqlQuery, this.props.useApolloCache)
    };

    callRestApi = () => {
        return makeApiCall(this.props.endpoint, this.props.parameters)
    };


    setData(data) {
        const dataAccessor = this.props.dataAccessor;
        const preProcessedData = this.props.dataPreProcess(data);
        this.props.componentCallback(preProcessedData, data);
        this.setState({
            columns: this.props.columns.concat(this.props.dynamicColumnGenerator(preProcessedData)),
            results: dataAccessor ? _.get(preProcessedData, dataAccessor) : preProcessedData,
            status: COMPONENT_STATUS.COMPLETED
        });
    }

    setError(error) {
        this.setState({error: error, status: COMPONENT_STATUS.ERRORED});
        this.props.errorCallback(error)
    }

    componentDidMount() {
        if (this.props.callApiOnComponentMount)
            this.reload(COMPONENT_STATUS.LOADING);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.endpoint !== this.props.endpoint ||
            prevProps.graphqlQuery !== this.props.graphqlQuery ||
            !_.isEqual(this.props.parameters, prevProps.parameters)) {
            this.reload(this.props.allowUpdating ? COMPONENT_STATUS.UPDATING : COMPONENT_STATUS.LOADING)
        }
    }

    renderTableComponent() {
        return <ReactTable
                filterable={this.props.filterableTable}
                data={this.state.results}
                initialState={{
                    pagination: {pageSize: this.props.pagination}
                }}
                columns={this.state.columns}
            />;
    }

    renderCustomComponent() {
        const ComponentToRender = this.props.componentToRender;
        if (this.state.results) {
            return (<ComponentToRender data={this.state.results}
                                       refreshData={this.callApi}
                                       {...this.props.componentToRenderProps}
            />);
        } else {
            return (<span>{'No data found'}</span>);
        }
    }

    getComponentToRender() {
        return this.props.componentToRender ? this.renderCustomComponent() : this.renderTableComponent()
    }

    render() {
        let componentToRender;
        switch (this.state.status) {
            case COMPONENT_STATUS.LOADING:
                componentToRender = <LoadingSpinner/>;
                break;
            case COMPONENT_STATUS.COMPLETED:
                componentToRender = this.getComponentToRender();
                break;
            case COMPONENT_STATUS.UPDATING:
                componentToRender = <Fragment>
                    <div>...Refreshing Data</div>
                    {this.getComponentToRender()} </Fragment>
                break;
            default:
                console.error('=============> this.state.error', this.state.error);
                componentToRender = this.props.errorComponentFunction(this.state.error)
        }
        return componentToRender
    }
}

ApiGetContainer.defaultProps = {
    allowUpdating: false,
    callApiOnComponentMount: true,
    columns: [],
    componentCallback: () => {},
    componentToRenderProps: {},
    dataPreProcess: _.identity,
    dynamicColumnGenerator: () => [],
    errorComponentFunction: (error) => <ErrorComponent error={error}/>,
    filterableTable: false,
    pagination: 100,
    showPageSizeOptions: true,
    useApolloCache: false,
    errorCallback: (error) => console.error('There was an error: ', error)
};

ApiGetContainer.propTypes = {
    allowUpdating: PropTypes.bool,
    graphqlQuery: PropTypes.string,
    callApiOnComponentMount: PropTypes.bool,
    columns: PropTypes.array,
    componentCallback: PropTypes.func,
    componentToRender: PropTypes.elementType,
    componentToRenderProps: PropTypes.object,
    errorComponentFunction: PropTypes.func,
    errorCallback: PropTypes.func,
    dataPreProcess: PropTypes.func,
    dataAccessor: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    dynamicColumnGenerator: PropTypes.func,
    endpoint: PropTypes.string,
    filterableTable: PropTypes.bool,
    pagination: PropTypes.number,
    parameters: PropTypes.object,
    showPageSizeOptions: PropTypes.bool,
    useApolloCache: PropTypes.bool
};

export default ApiGetContainer;
