import {makeApiCall} from "../../Api/makeApiCall";

export function parseGeographyCompositionResponse(response) {
    return response.data.map(geographyComposition => {
        const geoComposition = geographyComposition.name.toString();
        return {label: geoComposition, value: geoComposition};
    });
}

export function makeApiCallForGeographyComposition(){
    return makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/geographyComposition/`);
}
