import Page from "../Components/Page";
import React, {useState} from "react";
import {makeApiCall} from "../../Api/makeApiCall";
import OptionalTextBox from "../Scaffolding/OptionalTextBox";
import {toast} from "react-toastify";
import PeriodsDropdown from "../Components/Dropdowns/PeriodsDropdown";
import OutletsDropdown from "../Components/Dropdowns/OutletsDropdown";
import {apolloClient, makeApolloClientCall} from "../../Api/makeApolloApiCall";
import {currentPeriodQuery} from "../../queries/currentPeriodQuery";
import {ApolloProvider} from "@apollo/client";
import OptionalDropDownApi from "../Scaffolding/OptionalDropDownApi";
import {labelCurrentPeriodWeek, makeApiCallForPeriods} from "../Components/periodDropdownMethods";
import {
    makeApiCallForGeographyComposition,
    parseGeographyCompositionResponse
} from "../Components/geographyCompositionDropdown";
import LastKeithFilesRun from "./LastKeithFilesRun";
import ConfirmationModal from "../Components/ConfirmationModal";
import Checkbox from "../Components/Checkbox";
import {toastError, toastSuccess} from "../../utils/toast";
import {prefixFilterRules} from "../../utils/prefixFilterRules";
import CheckpointDropdown from "../Components/CheckpointDropdown";
import {invokeDAGviaApiPost} from "../../Api/invokeDag";
import {insightsStartDateQuery} from "../../queries/insightsStartDate";
import AirflowDagLogs from "../Components/airflow/dagLogs/AirflowDagLogs";
import {isMeasuresBetaAvailable} from "./beta";
import {addLabelToOptionsIfNotExist, getValueFromOptions} from "../../utils/maybeAddLabelToOptions";
import Select from "react-select";
import SelectDropdown from "../Components/Dropdowns/SelectDropdown";
import ReactSelectDropdownFromApi from "../Components/ReactSelectDropdownFromApi";

export const DAG_ID = 'measures_period_release_bq'

export const MeasuresTriggerDag = () => {
    const pageName = 'Trigger Measures';
    const [showBetaCheckbox, updateShowBetaCheckbox] = useState(false);
    const [measuresRetailerIds, updateMeasuresRetailerIds] = useState('');
    const [useRetailerIds, updateUseRetailerIds] = useState(false);
    const [measuresRunPrefix, updateMeasuresRunPrefix] = useState('');
    const [usePrefix, updateUsePrefix] = useState(false);
    const [period, updatePeriod] = useState(undefined);
    const [endingWeekId, updateEndingWeekId] = useState(undefined);
    const [useStartingWeekId, updateUseStartingWeekId] = useState(false);
    const [startingWeekId, updateStartingWeekId] = useState(undefined);
    const [insightsStartDate, updateInsightsStartDate] = useState(undefined);
    const [outletSelection, updateOutletDropdown] = useState(undefined);
    const [useGeographyComposition, updateUseGeographyComposition] = useState(false);
    const [geographyCompositionSelection, updateGeographyCompositionDropdown] = useState(undefined);
    const [snapshotSelection, updateSnapshotDropdown] = useState(undefined);
    const [snapshotOptions, updateSnapshotOptions] = useState([]);
    const [projectionsVersionSelection, updateProjectionsVersionDropdown] = useState(undefined);
    const [projectionsVersionOptions, updateProjectionsVersionOptions] = useState([]);
    const [marketSizeInfoVersionSelection, updateMarketSizeInfoVersionDropdown] = useState(undefined);
    const [storesServiceVersionSelection, updateStoresServiceVersionSelection] = useState(undefined);
    const [storesServiceVersionOptions, updateStoresServiceVersionOptions] = useState([]);
    const [marketSizeInfoVersionOptions, updateMarketSizeInfoVersionOptions] = useState([]);
    const [getTestProjectionsShowModal, updateGetTestProjectionsShowModal] = useState(false);
    const [filterOutInactiveItems, updateFilterOutInactiveItems] = useState(true);
    const [saveInputsForRun, updateSaveInputsForRun] = useState(false);

    const [checkpointPath, updateCheckpointPath] = useState(undefined)
    const [beta, setBeta] = useState(false);

    function onSubmitButtonClicked() {
        const data = {
            outlet: outletSelection.outletName,
            outlet_id: outletSelection.value,
            snapshot_period: snapshotSelection,
            markets_period: period,
            measures_run_prefix: usePrefix ? measuresRunPrefix : undefined,
            ending_week_id_filter: Number(endingWeekId),
            starting_week_id_filter: useStartingWeekId ? Number(startingWeekId) : undefined,
            projections_version: projectionsVersionSelection,
            stores_version: storesServiceVersionSelection,
            market_size_info_version: marketSizeInfoVersionSelection,
            geography_composition: useGeographyComposition ? geographyCompositionSelection : undefined,
            filter_out_inactive_items: filterOutInactiveItems,
            retailer_ids: useRetailerIds ? measuresRetailerIds : undefined,
            checkpoint_source_path: checkpointPath,
            is_beta: beta,
            should_store_inputs: saveInputsForRun
        };

        invokeDAGviaApiPost(DAG_ID, data)
            .then((response) => {
                if (response?.apiError)
                    toastError(toast, `measures_period_release DAG trigger failed with error ${JSON.stringify(response.apiError)}`)
                else
                    toastSuccess(toast, `measures_period_release DAG triggered successfully with response ${JSON.stringify(response)}`)
            })
            .catch(e => {
                toastError(toast, `measures_period_release DAG trigger failed with error ${JSON.stringify(e)}`)
            })
    }

    function onGetTestProjectionsButtonClicked() {
        return invokeDAGviaApiPost('get_metadata_keith', {})
            .then((response) => {
                if (response?.apiError)
                    toastError(toast, `get_metadata_keith DAG trigger failed with error ${JSON.stringify(response.apiError)}`)
                else
                    toastSuccess(toast, `get_metadata_keith DAG triggered successfully with response ${JSON.stringify(response)}`)
            })
            .catch(e => {
                toastError(toast, `get_metadata_keith DAG trigger failed with error ${JSON.stringify(e)}`)
            });
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    React.useEffect(() => {
        makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/productSnapshotVersions`)
            .then(result => {
                    const options = result.sort().reverse();
                    return updateSnapshotOptions(addLabelToOptionsIfNotExist(options));
                }
            );
        makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/projectionsVersions`)
            .then(result => {
                    updateProjectionsVersionDropdown(result[0])
                    return updateProjectionsVersionOptions(addLabelToOptionsIfNotExist(result));
                }
            );
        makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/storesVersions`)
            .then(result => {
                    updateStoresServiceVersionSelection(result[0]);
                    return updateStoresServiceVersionOptions(addLabelToOptionsIfNotExist(result));
                }
            );
        makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/marketSizeInfoVersions`)
            .then(result => {
                    updateMarketSizeInfoVersionDropdown(result[0])
                    return updateMarketSizeInfoVersionOptions(addLabelToOptionsIfNotExist(result));
                }
            );

        isMeasuresBetaAvailable().then(result => updateShowBetaCheckbox(result))

        makeApolloClientCall(currentPeriodQuery).then(response => {
            const record = response.records[0];
            if (!endingWeekId) {
                updateEndingWeekId(record.weekId);
            }
            if (!snapshotSelection) {
                updateSnapshotDropdown(record.legacyPeriod.toString())
            }
        });

        makeApolloClientCall(insightsStartDateQuery).then(response => {
            updateInsightsStartDate(response.records[0]?.firstWeekOfPeriod.endDate);
            updateStartingWeekId(response.records[0]?.firstWeekOfPeriod.weekId);
        });
    }, []);

    function isSubmitButtonDisabled() {
        return !outletSelection || !period || !snapshotSelection || !endingWeekId;
    }

    return (
        <ApolloProvider client={apolloClient}>
            <Page name={pageName}>
                <div className={'dag-trigger-group'}>
                    <div className={'switches-radio'}>
                    {showBetaCheckbox && <Checkbox id={'beta-checkbox'}
                                  checked={beta}
                                  labelText='beta'
                                  onChange={()=> setBeta(!beta)}
                    />}
                    </div>
                    {(<>
                        <div className={"custom-run-selections"}>
                            <div className={"custom-run-selections-column"}>
                                <ReactSelectDropdownFromApi
                                    onDropDownChange={(value) => updateEndingWeekId(value.value)}
                                    selectedDropDownValue={endingWeekId && endingWeekId.toString()}
                                    labelText={"End Week ID"}
                                    getData={() => makeApiCallForPeriods().then(response => labelCurrentPeriodWeek(response))}
                                />
                                <br/>
                                <OutletsDropdown onChange={(option) => {
                                    updateOutletDropdown(option);
                                }} value={outletSelection?.value}/>
                                <br/>
                                <PeriodsDropdown
                                    className={'data-row-dropdown'}
                                    updatePeriod={updatePeriod}
                                    periodLabel={'Market Period'}
                                    selectedPeriod={period}
                                    graphqlQuery={'{ marketPeriodList }'}/>
                                <br/>
                                <SelectDropdown
                                    id={'snapshot_dropdown'}
                                    inputLabel={"Snapshot"}
                                    onChange={(option) => updateSnapshotDropdown(option.value)}
                                    selectValue={snapshotSelection}
                                    options={snapshotOptions}
                                    placeholder={'Select Product Snapshot...'}
                                />
                                <br/>
                            </div>
                            <div className={"custom-run-selections-column"}>
                                <div className={"projections-version-row"}>
                                    <div className={'id-input'}>
                                        <span className='input-group-label'>Projections Version:</span>
                                        <Select className={'select-dropdown'}
                                                classNamePrefix={'select-dropdown'}
                                                  id={'projections-version-dropdown'}
                                                  onChange={(option) => {
                                                      updateProjectionsVersionDropdown(option.value);
                                                  }}
                                                  value={getValueFromOptions(projectionsVersionSelection, projectionsVersionOptions)}
                                                  options={projectionsVersionOptions}
                                                  placeholder={'Select Projections Version...'}
                                        />
                                    </div>
                                    <button className={"button secondary submit get-test-projections-submit"}
                                            type={"button"}
                                            onClick={() => updateGetTestProjectionsShowModal(true)}
                                    >
                                        Get Test Projections
                                    </button>
                                    {getTestProjectionsShowModal && <ConfirmationModal
                                        confirmationCallback={async () => {
                                            updateGetTestProjectionsShowModal(false);
                                            await onGetTestProjectionsButtonClicked();
                                        }}
                                        cancellationCallback={() => updateGetTestProjectionsShowModal(false)}>
                                        Are you sure you want to get test projections?
                                    </ConfirmationModal>
                                    }
                                </div>
                                <LastKeithFilesRun/>
                                <br/>
                                <SelectDropdown
                                    id={'stores-service-version_dropdown'}
                                    inputLabel={"Stores Service Version"}
                                    onChange={(option) => updateStoresServiceVersionSelection(option.value)}
                                    selectValue={storesServiceVersionSelection}
                                    options={storesServiceVersionOptions}
                                    placeholder={'Select Stores Services Version...'}
                                />
                                <br/>
                                <SelectDropdown
                                    id={'market-size-info-version_dropdown'}
                                    inputLabel={"Market Size Info Version"}
                                    onChange={(option) => updateMarketSizeInfoVersionDropdown(option.value)}
                                    selectValue={marketSizeInfoVersionSelection}
                                    options={marketSizeInfoVersionOptions}
                                    placeholder={'Select Market Size Info Version...'}
                                />
                                <br/>
                                <div className={'measures-item-filters'}>
                                    <span className='input-group-label'>Item Filters:</span>
                                    <div id={'check-boxes'}>
                                        <Checkbox
                                            id={'inactive_items'}
                                            labelText={'Include SN/SA/SG Items Only'}
                                            onChange={updateFilterOutInactiveItems}
                                            checked={filterOutInactiveItems}/>
                                    </div>
                                </div>
                                <br/>
                                <OptionalTextBox
                                    checkboxText={`Use an optional prefix for the dataset you create`}
                                    labelText={'Measures Prefix'}
                                    helpText={'Optional prefix for measures dataset'}
                                    onCheckBoxChange={updateUsePrefix}
                                    onTextBoxChange={(value) => updateMeasuresRunPrefix(prefixFilterRules(value))}
                                    checkBoxIsChecked={usePrefix}
                                    textInput={measuresRunPrefix}
                                />
                                <br/>
                                <div className={'id-input width-450'}>
                                    <OptionalDropDownApi
                                        checkBoxIsChecked={useStartingWeekId}
                                        onCheckBoxChange={updateUseStartingWeekId}
                                        checkboxText={'Use an optional Start Week ID for the dataset you create'}
                                        labelText={'Start Week ID'}
                                        selectedDropDownValue={startingWeekId && startingWeekId.toString()}
                                        onDropDownChange={updateStartingWeekId}
                                        onParseApiResponse={labelCurrentPeriodWeek}
                                        apiRequest={makeApiCallForPeriods}
                                        helpText={`Defaults to the first week of the"insights start" period: ${insightsStartDate}`}
                                    />
                                </div>
                                <br/>
                                <div className={'id-input width-450'}>
                                    <OptionalDropDownApi
                                        checkBoxIsChecked={useGeographyComposition}
                                        onCheckBoxChange={updateUseGeographyComposition}
                                        checkboxText={'Use an optional Geography Composition'}
                                        labelText={'Geography Composition'}
                                        selectedDropDownValue={geographyCompositionSelection}
                                        onDropDownChange={updateGeographyCompositionDropdown}
                                        onParseApiResponse={parseGeographyCompositionResponse}
                                        apiRequest={makeApiCallForGeographyComposition}
                                        helpText={'Optional Geography Composition'}
                                    />
                                </div>
                                <br/>
                                <div>
                                    <OptionalTextBox
                                        labelText={'Retailer IDs'}
                                        checkboxText={'Input a comma separated list of Retailer Ids'}
                                        checkBoxIsChecked={useRetailerIds}
                                        textInput={measuresRetailerIds}
                                        onCheckBoxChange={updateUseRetailerIds}
                                        onTextBoxChange={(value) => updateMeasuresRetailerIds(value && value.replace(/[^\d,]/g, ''))}
                                    />
                                </div>
                                <br/>
                                <CheckpointDropdown
                                    updateCheckpointPath={({checkpointPath}) => updateCheckpointPath(checkpointPath)}
                                    datasets={['cleanse_fill_extract', 'filtered_promo', 'promo_extract', 'store_insights']}
                                    tableData={['promo_parquet']}
                                    id={'checkpointdropdown'}
                                />
                                <br/>
                                <div className={'save-inputs'}>
                                        <Checkbox
                                            id={'save_inputs'}
                                            labelText={'Save Inputs'}
                                            onChange={()=> updateSaveInputsForRun(!saveInputsForRun)}
                                            checked={saveInputsForRun}/>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </>)}
                    <button type="button"
                            disabled={isSubmitButtonDisabled()}
                            className="button primary measures-trigger-submit"
                            onClick={onSubmitButtonClicked}
                    >
                        Submit
                    </button>
                </div>
                <br/>
                <AirflowDagLogs
                    processName={'measures_period_release_bq'}
                    includeDagName={false}
                    customColumns={[
                        {header: 'Outlet', accessorKey: 'conf.outlet', className: 'non-numeric-field'},
                        {header: 'Outlet ID', accessorKey: 'conf.outlet_id', className: 'non-numeric-field'},
                        {header: 'Period', accessorKey: 'conf.markets_period', className: 'non-numeric-field'}
                    ]}
                />
            </Page>
        </ApolloProvider>
    );
}

export default MeasuresTriggerDag;