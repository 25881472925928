import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';

class OptionalTextBox extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.checkBoxChanged = this.checkBoxChanged.bind(this);

        this.UUID = uuidv4();
    }

    checkBoxChanged(isChecked) {
        this.props.onCheckBoxChange(isChecked)
        if (!isChecked) {
            this.props.onTextBoxChange(undefined)
        }
    }

    render() {
        const {checkboxText, labelText, helpText, checkBoxIsChecked, textInput, onTextBoxChange, maxTextLength} = this.props;

        const checkBoxId = `checkbox-${this.UUID}`;

        return <>
            <div className="checkbox-container scaffolding-dag">
                <input className="checkbox test"
                       type="checkbox"
                       id={checkBoxId}
                       checked={checkBoxIsChecked}
                       onChange={() => this.checkBoxChanged(!checkBoxIsChecked)}
                />
                <label className={'checkbox-label'}
                       htmlFor={checkBoxId}>{checkboxText}</label>
            </div>
            <div className={'id-input'}>
                <span className='input-group-label'>{labelText}:</span>
                <input
                    id={'alternate-source-identifier-input'}
                    disabled={!checkBoxIsChecked}
                    className={'input-group-field'}
                    type='text'
                    value={textInput || ''}
                    maxLength={maxTextLength}
                    onChange={(e) => onTextBoxChange(e.target.value)}
                />
            </div>
            <div className="help-text">{helpText}</div>
        </>;
    }
}

OptionalTextBox.propTypes = {
    onCheckBoxChange: PropTypes.func.isRequired,
    onTextBoxChange: PropTypes.func.isRequired,
    checkBoxIsChecked: PropTypes.bool.isRequired,
    textInput: PropTypes.string,
    checkboxText: PropTypes.string,
    labelText: PropTypes.string,
    helpText: PropTypes.string,
    maxTextLength: PropTypes.number
}


export default OptionalTextBox