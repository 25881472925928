import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Page from "../../Components/Page";
import {BulkLoadArchiveButton, bulkLoadOptionsEnum} from "../BulkLoadArchiveButton";
import {RerunCleanseButton} from "../RerunCleanseButton";
import {CleanseSalesToParquetButton} from "../CleanseSalesToParquetButton";
import {FillAllForRetailer} from "../FillAllForRetailer";
import Checkbox from "../../Components/Checkbox";
import {makeApiCall, makeApiCallPost} from "../../../Api/makeApiCall";
import ButtonLoader from "../../../utils/components/ButtonLoader";
import {createDagRunConf} from "../../../Api/dagRunConf";
import {getUserEmail} from "../../../auth/accessTokenValidator";

export const ActionsTab = ({active, retailChain, retailerId}) => {

    const [fillAllDates, setFillAllDates] = useState(false);
    const [skipFillRetailerIds, setSkipFillRetailerIds] = useState([]);
    const [jsonToDeleteDate, setJsonToDeleteDate] = useState(null);

    const toggleFillAllDates = () => {
        setFillAllDates(!fillAllDates)
    }

    useEffect(() => {
        makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/skipFillRetailers/`)
            .then(data => {
                const skipFillRetailerIds = data['data'].map(retailer => retailer.retailerId);
                setSkipFillRetailerIds(skipFillRetailerIds)
            })
    }, []);

    return (<>
        <div id={'actions-tab'}>
            <Page name={'Reload'} displayHelmetHeader={false}>
                <BulkLoadArchiveButton retailerId={retailerId} fileType={bulkLoadOptionsEnum.sales}/>
                <BulkLoadArchiveButton retailerId={retailerId} fileType={bulkLoadOptionsEnum.items}/>
            </Page>
            <Page name={'Cleanse'} displayHelmetHeader={false}>
                <RerunCleanseButton retailerId={retailerId}/>
                <CleanseSalesToParquetButton retailerId={retailerId}/>
            </Page>
            <Page name={'Fill'} displayHelmetHeader={false}>
                <div className={'horizontal-control-block-element-left-actions-tab'}>
                    <Checkbox checked={fillAllDates}
                              labelText='Fill All Dates'
                              onChange={toggleFillAllDates}
                    />
                    <FillAllForRetailer retailerId={retailerId}
                                        active={active}
                                        skipFillRetailerIds={skipFillRetailerIds}
                                        fillAllDates={fillAllDates}
                    />
                    <ButtonLoader
                        buttonClassName={'button alert'}
                        buttonTitle={'Delete All Fill Data'}
                        buttonHasModal
                        modalHeader={'Delete All Fill Data'}
                        userAcknowledgedCheck
                        modalText={`Are you sure you want to delete all fill data for ${retailChain}?`}
                        axiosFunction={makeApiCallPost}
                        url={`${process.env.REACT_APP_EDP_API_BASE_URL}/dagTrigger`}
                        postBody={{
                            dagName: 'retailer_delete_specified_non_archive_data',
                            data: createDagRunConf({
                                retailer_ids: [retailerId],
                                assets_to_delete: ['FILL_TO_DELETE']
                            })
                        }}
                        toastTextFailure={`Delete All Fill Data for ${retailChain} has failed to kicked off.`}
                        toastTextSuccess={`Delete All Fill Data for ${retailChain} has successfully kicked off.`}
                    />
                </div>
            </Page>
            <Page name={'Data Clean up'} displayHelmetHeader={false}>
                <div className={'horizontal-control-block-element-left short-input'}>
                    <input
                        data-testid={'delete-json-calendar'}
                        className={'input-group-field delete-json-calendar'}
                        type="date"
                        onChange={(event) => setJsonToDeleteDate(event.target.value)}
                    />
                    <ButtonLoader
                        buttonClassName={'button alert'}
                        buttonTitle={`Delete All json Data`}
                        buttonHasModal
                        modalHeader={`Delete All json Data`}
                        userAcknowledgedCheck
                        disabled={!jsonToDeleteDate}
                        modalText={`Are you sure you want to delete All json Data for retailer ${retailerId} on ${jsonToDeleteDate}.\n
                                                        You will need to re-ingest the original file with corrected date`}
                        axiosFunction={makeApiCallPost}
                        url={`${process.env.REACT_APP_EDP_API_BASE_URL}/dagTrigger`}
                        postBody={{
                            dagName: 'delete_retailer_json_by_date',
                            data: createDagRunConf({
                                retailer_id: retailerId,
                                week_end_dates: [jsonToDeleteDate],
                                triggered_by: getUserEmail()
                            })
                        }}
                        toastTextFailure={`Delete All json Data for retailer ${retailerId} on ${jsonToDeleteDate} has failed to kicked off.`}
                        toastTextSuccess={`Delete All json Data for retailer ${retailerId} on ${jsonToDeleteDate} has successfully kicked off.`}
                    />
                </div>
            </Page>
            <Page name={'Other'} displayHelmetHeader={false}>
                <ButtonLoader
                    buttonClassName={'button alert'}
                    buttonTitle={'Delete All Processed Data'}
                    buttonHasModal
                    modalHeader={'Delete All Processed Data'}
                    userAcknowledgedCheck
                    modalText={`Are you sure you want to delete all processed data for ${retailChain}?`}
                    axiosFunction={makeApiCallPost}
                    url={`${process.env.REACT_APP_EDP_API_BASE_URL}/dagTrigger`}
                    postBody={{
                        dagName: 'retailer_delete_all_non_archive_data',
                        data: createDagRunConf({retailer_ids: [retailerId]})
                    }}
                    toastTextFailure={`Delete All Processed Data for ${retailChain} has failed to kicked off.`}
                    toastTextSuccess={`Delete All Processed Data for ${retailChain} has successfully kicked off.`}
                />
            </Page>
        </div>
    </>);
};

ActionsTab.defaultProps = {
    active: PropTypes.bool.isRequired,
    retailChain: PropTypes.string.isRequired,
    retailerId: PropTypes.number.isRequired
};

