import React, {useEffect} from "react";
import {EditFormDropdownInputField} from "../../Components/ReactTable/EditRecordForm";
import {getRetailerLookup} from "../../../utils/retailerMapCache";

export function RetailerInputField({form}) {

    const [retailerLookup, setRetailerLookup] = React.useState([])
    const [retailerOptions, setRetailerOptions] = React.useState([])
    const retailerId = form.watch('retailerId')

    useEffect(() => {
        const retailerLookup = getRetailerLookup();
        setRetailerLookup(retailerLookup)
        setRetailerOptions(Object.keys(retailerLookup).map(retailerId => ({value: Number(retailerId), label: `${retailerId} - ${retailerLookup[retailerId]?.retailChain || ""}`})))
    }, [])

    useEffect(() => {
        const lookupRetailTag = (retailerId) => {
            if (retailerId && retailerLookup) {
                const retailTag = retailerLookup[retailerId]?.retailTag
                if (retailTag) {
                    return retailTag
                }
            }
            return undefined
        }
        form.setValue('retailTag', lookupRetailTag(retailerId))
    }, [retailerId, retailerLookup, form])

    return <EditFormDropdownInputField id={'retailerId'} form={form} label={'Retailer'} options={retailerOptions} isRequired={true}/>
}
