import React from 'react';
import Page from '../../Components/Page';
import {columns} from './ContinuityTable';
import _ from 'lodash';
import ApiGetContainer from '../../../Api/ApiGetContainer';
import DynamicColumns from './DynamicColumns';
import {useQuery} from '@apollo/client';
import gql from 'graphql-tag';
import moment from 'moment';
import {sortAscendingWithNullsFirst} from '../../../utils/sorting';

export function getWhatWillFillForRetailerQuery(timeServiceResponse) {
    const startWeekId = timeServiceResponse.data.convertDateToPeriod[0].weekId;
    const today = moment(Date.now()).format('yyyy-MM-DD');
    return `
    query{
        data:storeSalesDateValidation(skipFillChecks: false, startWeekId: ${startWeekId}, fillEndDate: "${today}"){
            retailerId
            dates:datesToFill{
                weekEndDate
            }
            storeId
        }
    }
`
}

export function getDynamicColumns({data}) {
    const allDates = [];
    data.forEach(store => {
        store.dates.forEach(date => {
            allDates.push(date);
        });
    });
    const uniqueSortedDates = _.uniq(allDates).sort().reverse();

    return uniqueSortedDates.map(date => {
        return {
            id: date,
            header: date,
            enableColumnFilter: false,
            sortingFn: sortAscendingWithNullsFirst,
            accessorFn: (retailer) => {
                const possiblyFoundDate = retailer.dates.find(rowDate => rowDate === date);
                return possiblyFoundDate ? 'X' : '';
            }
        }
    });
}

export function reduceByStoreId({data}) {
    const startDate = moment(Date.now()).add(-5, 'weeks').format('yyyy-MM-DD');
    const getDatesFromCurrentStore = currentValue => currentValue.dates.map(date => date.weekEndDate).filter(date => date >= startDate);
    return {
        data: data.reduce((accumulator, currentValue) => {
            let possiblyFoundRetailerAndStore = accumulator.find(retailerAndStore =>
                retailerAndStore.retailerId === currentValue.retailerId &&
                retailerAndStore.storeId === currentValue.storeId);

            if (possiblyFoundRetailerAndStore) {
                possiblyFoundRetailerAndStore.dates = possiblyFoundRetailerAndStore.dates
                    .concat(getDatesFromCurrentStore(currentValue));
            } else {
                const datesFromCurrentStore = getDatesFromCurrentStore(currentValue);
                !_.isEmpty(datesFromCurrentStore) && accumulator.push({
                    retailerId: currentValue.retailerId,
                    storeId: currentValue.storeId,
                    dates: datesFromCurrentStore
                })
            }
            return accumulator;
        }, [])
    }
}

export const convertDateToPeriodQuery = gql`
    query convertDateToPeriod ($date: String!) {
        convertDateToPeriod(date:$date){
            weekId
        }
    }`;

const WhatWillFillPage = () => {
    const startWeekId = useQuery(
        convertDateToPeriodQuery,
        {
            variables: {
                date: moment(Date.now()).add(-365, 'days').format('yyyy-MM-DD')
            },
        },
    );

    return (
        <Page name="What Will Fill">
            {startWeekId?.data && <ApiGetContainer
                graphqlQuery={getWhatWillFillForRetailerQuery(startWeekId)}
                useApolloCache={true}
                componentToRender={DynamicColumns}
                dataPreProcess={reduceByStoreId}
                componentToRenderProps={
                    {
                        dynamicColumnsFunc: getDynamicColumns,
                        columns: columns,
                    }
                }
            />}
        </Page>
    );
};
export default WhatWillFillPage;