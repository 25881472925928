import _ from "lodash";
import {ContextMenuTrigger} from "react-contextmenu";
import cleanseIcon from "../../../assets/cleanse.png";
import StoreClosedIcon from "../StoreTotals/Components/StoreClosedIcon";
import React from "react";

export function getAccessor(retailerId, date, outlierDatesByStore, displayDataFunction, styleForOutlierFunction, styleForConfigsFunction, contextMenuId, setTimestamp, cellValueClicked) {
    return (store) => {
        const possiblyFoundDate = store.weekEndData.find(data => data.date === date);
        const possiblyFoundOutlier = possiblyFoundDate && outlierDatesByStore.find(outlier => (outlier.storeId === store.storeId && outlier.weekEndDate === date));
        const data = displayDataFunction(possiblyFoundDate, possiblyFoundOutlier)
        const hasFill = possiblyFoundDate && possiblyFoundDate.fill && !_.isEmpty(possiblyFoundDate.fill);
        const hasDoNotOrAlwaysFill = possiblyFoundDate && (possiblyFoundDate.doNotFill || possiblyFoundDate.alwaysFill);
        const cellContainerClassName = [
            styleForOutlierFunction(possiblyFoundOutlier),
            styleForConfigsFunction(possiblyFoundDate),
            'total-dollars-cell-container'
        ].filter(Boolean).join(' ');
        return {
            component: <ContextMenuTrigger id={contextMenuId}
                                           retailerId={retailerId}
                                           date={date}
                                           storeIds={[store.storeId]}
                                           storeStart={possiblyFoundDate?.storeStart}
                                           storeStop={possiblyFoundDate?.storeStop}
                                           doNotFill={possiblyFoundDate?.doNotFill}
                                           alwaysFill={possiblyFoundDate?.alwaysFill}
                                           storeClosures={store.storeClosures}
                                           setTimestamp={setTimestamp}
                                           header={false}
                                           collect={_.identity}>
                <div className={cellContainerClassName}>
                    {
                        data &&
                        <div className={'cleanse-cell-details'} onClick={() => cellValueClicked(store, possiblyFoundDate)}>
                            <img alt={'cleanse'} className={'cleanse-icon icon-spacing'} src={cleanseIcon}/>
                            <span id={'cleansed'}>{data}</span>
                        </div>
                    }
                    <div>
                        {hasDoNotOrAlwaysFill && <div className="fas fa-fill-drip fill-config-icon icon-spacing"/>}
                        {hasFill &&
                            <div className={'fill-cell-details'}
                                 onClick={() => cellValueClicked(store, possiblyFoundDate.fill)}>
                                <div id={'has-fill-icon'} className="fas fa-fill-drip icon-spacing"/>
                                <span id={'fill'}>{displayDataFunction(possiblyFoundDate && possiblyFoundDate.fill)}</span>
                            </div>
                        }
                    </div>
                    {store.closedDate <= date &&
                        <i id={'store-closed'} className={'fas fa-store-slash store-closed-color'}/>}
                    <StoreClosedIcon date={date} storeClosures={store.storeClosures}/>
                </div>
            </ContextMenuTrigger>,
            className: cellContainerClassName
        }
    };
}