import {round} from "lodash";
import RetailerContacts from "./RetailerContacts";
import React from "react";
import {queryParamFilterReactTable7} from "../../utils/QueryParamFilter";
import FileLink from "../IngestionLogs/Columns/FileLink";
import {nameDisplay} from "./NameDisplay";
import matchSorter from "match-sorter";
import RetailerIdAndName from "../Components/RetailerIdAndName";
import {getRetailerName} from "../../utils/retailerMapCache";
import {convertDateTime} from "../../utils/formatUtil";
import AlertActions from "./AlertActions";
import {getBaseFileName} from "../../utils/GetBaseFileName";
import {sortAscendingWithNullsFirst, sortAscendingWithNullsLast} from "../../utils/sorting";
import {addLabelToOptionsIfNotExist} from "../../utils/maybeAddLabelToOptions";
import ReactTableFilterDropdown from "../ReactTable/ReactTableFilterDropdown";
import {isAlertTaskLinkable} from "./OpsAlertsContent";


export const PAST_DUE_COLUMNS = [
    {
        header: 'Retailer',
        accessorKey: 'retailerId',
        cell: ({row}) => <RetailerIdAndName retailerId={row.original.retailerId}/>,
        className: 'non-numeric-field',
        filterFn: (row, columnIds, filterValue) => !filterValue || Boolean(`${row.original.retailerId} ${getRetailerName(row.original.retailerId)?.toLowerCase()}`.includes(filterValue.toLowerCase()))
    },
    {
        header: 'Roll-Up Day Of Week',
        accessorKey: 'rollUpDayOfWeek',
        enableColumnFilter: false,
        className: 'non-numeric-field'
    },
    {
        header: 'Latest Cleansed',
        id: 'maxSalesEndDate',
        accessorFn: row => row.maxSalesEndDate || 'NA',
        className: 'non-numeric-field',
        sortingFn: (rowA, rowB, columnId) => {
            const rowBValue = rowB.getValue(columnId);
            const rowAValue = rowA.getValue(columnId);
            return (rowBValue === 'NA') - (rowAValue === 'NA') || (-(rowAValue < rowBValue) || +(rowAValue > rowBValue));
        },
        enableColumnFilter: false,
        enableSorting: true
    },
    {
        header: 'Days Past Due',
        id: 'daysPastDue',
        className: 'numeric-field',
        accessorFn: row => row.daysPastDue,
        sortingFn: (rowA, rowB, columnId) => {
            const rowBValue = rowB.getValue(columnId);
            const rowAValue = rowA.getValue(columnId);
            return sortAscendingWithNullsFirst(rowAValue, rowBValue);
        },
        enableColumnFilter: false,
        enableSorting: true
    },
    {
        header: 'Weeks Past Due',
        id: 'weeksPastDue',
        accessorFn: (row) => !row.daysPastDue ? null : round(row.daysPastDue / 7, 1),
        className: 'numeric-field',
        sortingFn: (rowA, rowB, columnId) => {
            const rowBValue = rowB.getValue(columnId);
            const rowAValue = rowA.getValue(columnId);
            return sortAscendingWithNullsFirst(rowAValue, rowBValue);
        },
        enableColumnFilter: false,
        foldable: true
    },
    {
        cell: ({row}) => {
            return <RetailerContacts
                accountId={row.original.salesforceRetailer?.accountId}
                retailerId={row.original.retailerId}
            />
        },
        id: 'Contact',
        header: 'Contact',
        enableColumnFilter: false,
        disableSearch: true
    },
    {
        header: 'Active',
        id: 'Active',
        accessorFn(rowData) {
            return rowData.salesforceRetailer.active === null ? "Salesforce Not Found" : rowData.salesforceRetailer.active.toString();
        },
        className: 'non-numeric-field',
        filterFn: (row, columnIds, filterValue) => {
            if (filterValue === 'all') {
                return true;
            }
            return row.original?.salesforceRetailer.active;
        },
        filter: (theseProps) => {
            const options = [
                {value: "true", label: "Active Retailers"},
                {value: "all", label: "All Retailers"}
            ];
            return <ReactTableFilterDropdown
                id={"active_retailer"}
                options={options}
                onChange={(selected) => theseProps.column.setFilterValue(selected.value)}
                selectedValue={theseProps.column.getFilterValue()}
            />
        }
    },
    {
        header: 'Company Type',
        accessorKey: 'salesforceRetailer.companyType',
        className: 'non-numeric-field'
    },
    {
        header: 'Account Manager',
        accessorKey: 'salesforceRetailer.accountManager',
        className: 'non-numeric-field'
    },
    {
        header: 'Retailer Association',
        accessorKey: 'salesforceRetailer.retailerAssociation',
        className: 'non-numeric-field'
    },
]


export const getOpsAlertColumns = (taskTypes) => {
    return [
        {
            header: 'Timestamp',
            accessorKey: 'timestamp',
            filter: queryParamFilterReactTable7('timestamp'),
            cell: ({getValue, row}) => {
                const newDate = convertDateTime(getValue())
                return isAlertTaskLinkable(row.task_name) ?
                    <a href={`/ingestedLogs?days=7&hideOnboarding=false&retailer=${row.retailer_id}`}>{newDate}</a> : newDate;
            },
            maxSize: 100,
            filterFn: (row, columnIds, filterValue) => !filterValue || Boolean(`${convertDateTime(row.original.timestamp).toLowerCase()})}`.includes(filterValue.toLowerCase()))

        },
        {
            header: 'Alert Id',
            accessorKey: 'insertId',
            maxSize: 100
        },
        {
            header: 'Alert',
            accessorKey: 'task_name',
            filter: (props) => <ReactTableFilterDropdown
                id={"alert_type"}
                queryStringParamId={'task_name'}
                options={addLabelToOptionsIfNotExist(taskTypes)}
                onChange={props.column.setFilterValue}
                isClearable={true}/>,
            filterFn: (row, columnIds, filterValue) => matchSorter([row], filterValue, {keys: [`original.task_name`]}).length > 0,
            size: 180
        },
        {
            header: 'Retailer',
            accessorKey: 'retailer_id',
            cell: ({row}) => {
                if (row.original.retailerIds) {
                    return <>[{row.original.retailerIds.join(', ')}]</>
                }
                return <RetailerIdAndName retailerId={row.original.retailer_id}/>
            },
            filter: queryParamFilterReactTable7('retailerId'),
            filterFn: (row, columnIds, filterValue) => {
                if (!filterValue) {
                    return true;
                }

                if (row.original.retailerIds) {
                    return Boolean(`${row.original.retailerIds.join(', ')}`.includes(filterValue.toLowerCase()))
                }
                return Boolean(`${row.original.retailer_id} ${getRetailerName(row.original.retailer_id)?.toLowerCase()}`.includes(filterValue.toLowerCase()))
            }
        },
        {
            header: 'File',
            id: 'file',
            accessorFn: (row) => {
                const payload = row?.payload;

                if (payload) {
                    try {
                        return JSON.parse(payload);
                    } catch {
                    }
                }
                return undefined;
            },
            cell: ({getValue}) => getValue() && getValue().archived_file_url ?
                getValue().archived_file_url.split(',').map(fileValue => <FileLink key={fileValue}
                                                                                   displayText={nameDisplay({
                                                                                       ...getValue(),
                                                                                       archived_file_url: fileValue
                                                                                   })}
                                                                                   gcsFileLocation={fileValue}/>) : null,
            filter: queryParamFilterReactTable7('file'),
            filterFn: (row, columnIds, filterValue) => {
                if (!filterValue) return true
                const archivedFileUrl = row?.original?.archived_file_url
                if (!archivedFileUrl) return false
                const archivedFileUrls = archivedFileUrl.split(',')
                return archivedFileUrls.some(archivedFileUrl => {
                    const archiveFileName = getBaseFileName(archivedFileUrl.substring(archivedFileUrl.lastIndexOf('/') + 1))
                    return archiveFileName && archiveFileName.toLowerCase().includes(filterValue.toLowerCase())
                })
            },
            sortingFn: (rowA, rowB) => {
                const archivedFileUrlA = rowA?.original?.archived_file_url?.split(',')[0];
                const archivedFileUrlB = rowB?.original?.archived_file_url?.split(',')[0];

                const fileNameA = archivedFileUrlA ? archivedFileUrlA.substring(archivedFileUrlA.lastIndexOf('/') + 1) : null
                const fileNameB = archivedFileUrlB ? archivedFileUrlB.substring(archivedFileUrlB.lastIndexOf('/') + 1) : null
                return !fileNameA ? 1 : !fileNameB ? -1 : (fileNameA.toLowerCase() > fileNameB.toLowerCase()) ? 1 : -1
            },
            className: 'non-numeric-field'
        },
        {
            header: 'Actions',
            accessorFn: (x) => {
                return {
                    falsePositive: x.falsePositive,
                    acknowledged: x.acknowledged,
                    insertId: x.insertId,
                    notes: x.notes,
                    updateAlertDataFunction: x.updateAlertDataFunction,
                    payload: x.payload,
                    retailerId: x.retailer_id,
                    taskName: x.task_name,
                    partitionTime: x.partitionTime,
                    highlightedEvents: x.highlighted_events
                }
            },
            enableColumnFilter: false,
            maxSize: 100,
            cell: ({getValue}) => {
                return <AlertActions value={getValue()}/>;
            }
        },
        {
            header: 'Assigned',
            accessorKey: 'assignedUser',
            className: 'non-numeric-field'
        },
        {
            id: "highlightedEvents",
            header: 'Events',
            maxSize: 80,
            accessorFn: (x) => x.highlighted_events ? x.highlighted_events.length : 0,
            className: 'numeric-field',
            enableColumnFilter: false,
            sortingFn: (rowA, rowB, columnId) => {
                const highlightedEventsA = rowA.getValue(columnId);
                const highlightedEventsB = rowB.getValue(columnId);
                return sortAscendingWithNullsLast(highlightedEventsA, highlightedEventsB);
            }
        },
        {
            header: 'Notes',
            accessorKey: 'notes',
            className: 'non-numeric-field'
        }
    ];
};
