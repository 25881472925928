import React from "react";
import TotalsSearchOptionsModal from "./TotalsSearchOptionsModal";
import * as PropTypes from "prop-types";
import PeriodsDropdown from "../../Components/Dropdowns/PeriodsDropdown";
import {getStartAndEndDatesFromPeriodDetails} from "./getStartAndEndDatesFromPeriodDetails";

export const TotalsSearchOptions =
    ({
         showSearchTotalsModal,
         confirmationCallback,
         onRetailerChange,
         cancellationCallback,
         retailerId,
         endPeriod,
         percentThreshold,
         dataSelection,
         includeAllData,
         includePlu,
         includeIfps,
         includeFillOnly,
         productSnapshotOnly,
         shouldRenderChart,
         stateFilterOptions,
        stateFilter,
         openStoresOnlyFilter
     }) => {
        return <>
            <div className={'advanced-options-row'}>
                <div className={'pair'}>
                    <label className={'search-totals-label'} data-testid={"search-totals-retailer-id-label"}>Retailer ID</label>
                    <input
                        id={'retailer_id_totals'}
                        data-testid={"retailer-id-totals-input"}
                        className={'retailer-id-totals'}
                        type={'text'}
                        value={retailerId}
                        onChange={(event) => onRetailerChange(event.target.value)}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                confirmationCallback({});
                            }
                        }}
                    />
                </div>
                <div data-testid={"periods-dropdown-wrapper"}>
                    <PeriodsDropdown
                        id={'period'}
                        updatePeriod={async (_, period) => {
                            const {startDate, endDate} = getStartAndEndDatesFromPeriodDetails(period.weeks);
                            confirmationCallback({
                                startDate: startDate,
                                endPeriod: {...period, endDate: endDate},
                                endDate: endDate
                            })
                        }}
                        selectedPeriod={endPeriod && endPeriod.periodAbbreviation}
                        graphqlQuery={'{ periodList:periodDetails { weeks { weekId endDate } periodAbbreviation quadId } }'}
                        periodsFunction={(result) => result.periodList}
                        isDisabled={includeAllData}
                        toastErrorMessage={"An error occurred while loading the periods dropdown. Please reload the page."}
                    />
                </div>
                <i id={"edit-show-search-total-modal"} data-testid={"edit-show-search-total-modal"} className="fas fa-edit"
                   onClick={cancellationCallback}/>
            </div>
            {showSearchTotalsModal && endPeriod &&
            <TotalsSearchOptionsModal
                confirmationCallback={confirmationCallback}
                cancellationCallback={cancellationCallback}
                retailerId={retailerId}
                onRetailerChange={onRetailerChange}
                endPeriod={endPeriod}
                percentThreshold={percentThreshold}
                dataSelection={dataSelection}
                includeAllData={includeAllData}
                includePlu={includePlu}
                includeIfps={includeIfps}
                includeFillOnly={includeFillOnly}
                productSnapshotOnly={productSnapshotOnly}
                shouldRenderChart={shouldRenderChart}
                stateFilterOptions={stateFilterOptions}
                stateFilter={stateFilter}
                openStoresOnlyFilter={openStoresOnlyFilter}
            />}
        </>;

    }

TotalsSearchOptions.propTypes = {
    cancellationCallback: PropTypes.func,
    showSearchTotalsModal: PropTypes.any,
    endPeriod: PropTypes.any,
    confirmationCallback: PropTypes.func,
    retailerId: PropTypes.any,
    onRetailerChange: PropTypes.func,
    percentThreshold: PropTypes.number,
    dataSelection: PropTypes.string,
    includePlu: PropTypes.bool,
    includeFillOnly: PropTypes.bool,
    shouldRenderChart: PropTypes.bool,
    productSnapshotOnly: PropTypes.bool,
    stateFilterOptions: PropTypes.array,
    stateFilter: PropTypes.object,
    openStoresOnlyFilter: PropTypes.bool
};