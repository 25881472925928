import {findBestAddress, findUsableAddress} from "./address";
import {geocodeSearch} from "./geocode";
import _ from "lodash";
import {placesBestAddressSearch} from "./places";
import {getRetailerName} from "../../utils/retailerMapCache";

export const findBestAddressForLocation = (location, geocodingLib) => geocodeSearch({location}, geocodingLib).then(findBestAddress)

export const addressSearchString = (address) => {
    let result = undefined
    const addElement = (value, separator) => {
        if (_.isEmpty(value)) return
        result = _.isEmpty(result) ? value : `${result}${separator || ''} ${value}`
    }
    addElement(address.addr1)
    addElement(address.addr2)
    addElement(address.city, ',')
    addElement(address.state, ',')
    addElement(address.zip1)
    addElement(address.country, ',')
    return result
}

export const findBestAddressAndLocationForAddress = async (address, retailerId, geocodingLib, placesLib, map) => {
    // const searchAddressElement = (value, addComma) => _.isEmpty(value) ? '' : `${addComma ? ',' : ' '} ${value}`
    const searchAddress = addressSearchString(address)
    if (!searchAddress) return undefined
    const geocodeSearchResults = await geocodeSearch({address: searchAddress}, geocodingLib)
    // console.log("=============> geocodeSearchResults: ", geocodeSearchResults)
    const bestGeocodeSearchResult = findUsableAddress(geocodeSearchResults, ['addr1', 'city', 'state', 'zip1', 'country'])
    if (bestGeocodeSearchResult) return bestGeocodeSearchResult
    if (!_.isEmpty(address.addr1) || !_.isEmpty(address.addr2)) {
        const searchAddressWithoutCountry = addressSearchString({...address, country: undefined})
        if (!_.isEmpty(searchAddressWithoutCountry)) {
            const placesAddressSearchResults = await placesBestAddressSearch({query: searchAddressWithoutCountry}, geocodingLib, placesLib, map)
            // console.log("=============> placesAddressSearchResults: ", placesAddressSearchResults)
            const bestPlacesAddressSearchResult = findUsableAddress(placesAddressSearchResults, ['addr1', 'city', 'state', 'zip1', 'country'])
            if (bestPlacesAddressSearchResult) return bestPlacesAddressSearchResult
        }
    }
    const retailerName = getRetailerName(retailerId)
    if (retailerName && (!_.isEmpty(address.city) || !_.isEmpty(address.state) || !_.isEmpty(address.country))) {
        const searchAddressWithRetailerName =
            `${retailerName} in ` +
            addressSearchString({city: address.city, state: address.state, country: address.country})
        const placesRetailerSearchResults = await placesBestAddressSearch({query: searchAddressWithRetailerName}, geocodingLib, placesLib, map)
        // console.log("=============> placesRetailerSearchResults: ", placesRetailerSearchResults)
        const bestPlacesRetailerSearchResult = findUsableAddress(placesRetailerSearchResults, ['addr1', 'city', 'state', 'zip1', 'country'])
        if (bestPlacesRetailerSearchResult) return bestPlacesRetailerSearchResult
    }
    // console.log("=============> Unable to find good address, defaulting to best address we can find in the original geocode search")
    return findBestAddress(geocodeSearchResults)
}

