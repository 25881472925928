function getFillQuery(retailerId, includePlu, includeIfps, productSnapshotOnly, startDateClause, endDateClause, stateFilterClause, openStoresOnlyClause, useSkipStores = false) {
    return `fill:salesIngestionSummary(retailerId: ${retailerId}, includePlu: ${includePlu}, includeIfps: ${includeIfps}, useSkipStores: ${useSkipStores}, sources: fill, productSnapshotOnly: ${productSnapshotOnly}${startDateClause}${endDateClause}${stateFilterClause}${openStoresOnlyClause})
            {
                errors {
                    retailerId
                    storeId
                    weekEndDate
                    dollars
                    units
                }
                stores {
                    storeId 
                    storeName
                    retailerId 
                    weekEndData{date dollars units source archiveFilenames}
                }
                rollUpDates
                weekEndDates
            }`;
}

export function getGraphqlQuery(dataSelection, dataFormat, retailerId, includePlu, includeIfps, productSnapshotOnly, startDateClause, endDateClause, stateFilterClause, includeFillOnly, openStoresOnlyClause, useSkipStores = false) {
    const fillQuery = getFillQuery(retailerId, includePlu, includeIfps, productSnapshotOnly, startDateClause, endDateClause, stateFilterClause, openStoresOnlyClause, useSkipStores);
    return `
    {
        # ${dataSelection + dataFormat}
        sales:salesIngestionSummary(retailerId: ${retailerId}, includePlu: ${includePlu}, includeIfps: ${includeIfps}, includeFilledWeeksOnly: ${includeFillOnly}, useSkipStores: ${useSkipStores}, sources: cleansed, productSnapshotOnly: ${productSnapshotOnly}${startDateClause}${endDateClause}${stateFilterClause}${openStoresOnlyClause})
        {
            errors {
                retailerId
                storeId
                weekEndDate
                dollars
                units
            }
            stores {
                storeId 
                storeName
                retailerId 
                weekEndData{date dollars units source archiveFilenames}
            }
            rollUpDates
            weekEndDates
        }
        storeStart(retailerId: ${retailerId}) {dates{storeId, salesEndDate}}
        storeStop(retailerId: ${retailerId}) {dates{storeId, salesEndDate}}
        alwaysFill(retailerId: ${retailerId}${stateFilterClause}${openStoresOnlyClause}) {dates{storeId, salesEndDate}}
        doNotFill(retailerId: ${retailerId}${stateFilterClause}${openStoresOnlyClause}) {dates{storeId, salesEndDate}}
        storeClosures(retailerId: ${retailerId}) { storeId startDate endDate }
        stores:storesWithSkipConfiguration(retailerId: ${retailerId}, skipConfigName: "skipStoreTotals"${stateFilterClause}${openStoresOnlyClause}) { storeId storeName closedDate }
        outliers:storeSalesDataOutliers(retailerId: ${retailerId}, percentDifferenceThreshold: 0, percentDifferenceMeasure: ${dataSelection}${startDateClause}${endDateClause}, includePlu: ${includePlu}, includeIfps: ${includeIfps}, productSnapshotOnly: ${productSnapshotOnly}${stateFilterClause}${openStoresOnlyClause})
        {
            data {
                retailerId
                storeId
                weekEndDate
                dollarCount
                unitCount
                averageDollarCount
                averageUnitCount
                diffFromAverageDollar
                diffFromAverageUnit
            }
            errors {
                retailerId
                storeId 
                weekEndDate
                unitCount
                averageUnitCount
                diffFromAverageUnit
                dollarCount
                averageDollarCount
                diffFromAverageDollar
            }
        }
        ${fillQuery}
    }`;
}