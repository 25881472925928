import Page from '../Components/Page';
import React, {useState} from 'react';
import ButtonLoader from "../../utils/components/ButtonLoader";
import {getUserEmail} from "../../auth/accessTokenValidator";
import {invokeDAGviaApiPost} from "../../Api/invokeDag";
import {toastError, toastSuccess} from "../../utils/toast";
import {toast} from 'react-toastify';
import Checkbox from "../Components/Checkbox";
import ConfirmationModal from "../Components/ConfirmationModal";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import _ from "lodash";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD, RETAILER_ID_FIELD, RETAILER_ID_NAME_COLUMN} from "../Components/ReactTable/common_custom_columns";
import {serviceRetailerDropdown} from "../../utils/retailerDisplayFormatter";

export const FillAllMultipleRetailers = () => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/fillAllMultipleRetailers/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed, onDeleteAllConfirmed} = useServiceBase({endpointUrl});

    const [fillEndDate, updateFillEndDate] = useState(undefined);
    const [fillAllDates, updateFillAllDates] = useState(false);
    const [fillAllDatesModalIsOpen, updateFillAllDatesModalIsOpen] = useState(false);

    function onChangeDate(event) {
        updateFillEndDate(event.target.value)
    }

    function isSubmitButtonDisabled() {
        return !data ||  _.isEmpty(data) || data.data.length === 0;
    }

    function isFillUntilSubmitButtonDisabled() {
        return !data ||  _.isEmpty(data) || data.data.length === 0 || !fillEndDate;
    }

    function getRetailerList() {
        return !_.isEmpty(data) ? data.data.map(x => x.retailerId) : []
    }

    function getBulletedRetailers() {
        return `* ${(getRetailerList().join("\n * "))}`;
    }

    function cancelFillAllDatesModal() {
        updateFillAllDatesModalIsOpen(false)
    }

    function confirmFillAllDatesModal() {
        updateFillAllDates(true)
        updateFillAllDatesModalIsOpen(false)
    }

    function fillAllDatesClicked(value) {
        if (value) {
            updateFillAllDatesModalIsOpen(true)
        } else {
            updateFillAllDates(false)
        }
    }

    function onSubmitButtonClicked() {
        invokeFillAllDatesDag({
            retailer_id: getRetailerList(),
            fill_all_dates: fillAllDates,
            triggered_by: getUserEmail()
        });
    }

    function onFillUntilSubmitButtonClicked() {
        invokeFillAllDatesDag({
            retailer_id: getRetailerList(),
            fill_end_date: fillEndDate,
            fill_all_dates: fillAllDates,
            triggered_by: getUserEmail()
        });
    }

    function invokeFillAllDatesDag(dagData) {
        invokeDAGviaApiPost('fill_all_dates', dagData)
            .then((response) => {
                if (response?.apiError)
                    toastError(toast, `fill_all_dates DAG trigger failed with error [${JSON.stringify(response.apiError)}]`)
                else
                    toastSuccess(toast, `fill_all_dates DAG triggered successfully for retailers [${dagData.retailer_id}]`)
            })
            .catch(e => {
                toastError(toast, `fill_all_dates DAG trigger failed with error [${e}]`)
            })
    }

    return (
        <>
            {fillAllDatesModalIsOpen && <ConfirmationModal confirmationCallback={confirmFillAllDatesModal}
                                                           cancellationCallback={cancelFillAllDatesModal}
                                                           headerText={"Confirm Fill All Dates"}
            >
                <p>This will rerun all of fill and all of promo for the selected retailers, are you sure?</p>
            </ConfirmationModal>}
            <Page name={"Fill All: Multiple Retailers"}>
                <div className="fill-all-retailer-dag">
                    <input
                        data-testid="date-fill-multi"
                        className="date-fill-multi"
                        type="date"
                        onChange={onChangeDate}
                    />
                    <ButtonLoader type="button"
                                  buttonHasModal
                                  modalHeader={`Are you sure?`}
                                  modalText={`Submit Fill Until **${fillEndDate}** for Listed Retailers: \n${(getBulletedRetailers())}`}
                                  buttonClassName="button primary"
                                  className={"submit-fill-multi"}
                                  disabled={isFillUntilSubmitButtonDisabled()}
                                  onSubmitCallback={onFillUntilSubmitButtonClicked}
                                  buttonTitle={"Submit Fill Until for Listed Retailers"}
                    />
                </div>
                <br/>
                <div className="fill-all-retailer-dag">
                    <Checkbox checked={fillAllDates}
                              labelText='Rerun Fill and PROMO for Selected Retailers'
                              onChange={fillAllDatesClicked}
                    />
                </div>
                <div className="fill-all-retailer-dag">

                    <ButtonLoader type="button"
                                  buttonHasModal
                                  modalHeader={`Are you sure?`}
                                  modalText={`Submit Fill All for Listed Retailers: \n${(getBulletedRetailers())}`}
                                  buttonClassName="button primary"
                                  disabled={isSubmitButtonDisabled()}
                                  onSubmitCallback={onSubmitButtonClicked}
                                  className={"submit-fill-all"}
                                  buttonTitle={"Submit Fill All Retailers"}
                    />
                </div>
                <Page name={"Listed Retailers"} >
                    <LoadingWrapper loading={loading}>
                        <EditableReactTableV2
                            filterable
                            data={data.data}
                            columns={[RETAILER_ID_NAME_COLUMN]}
                            actionButtonsProps={{showDeleteAllButton: true}}
                            initialState={{ pagination: { pageSize: 25 }, sorting: [{ id: "retailerIdName", desc: false }] }}
                            editTable={[INDEX_FIELD, RETAILER_ID_FIELD(serviceRetailerDropdown().data)]}
                            onRowEditConfirmed={onRowEditConfirmed}
                            onRowDeleteConfirmed={onRowDeleteConfirmed}
                            onDeleteAllConfirmed={onDeleteAllConfirmed}
                        />
                    </LoadingWrapper>
                </Page>
            </Page>
        </>
    );
}
export default FillAllMultipleRetailers;