import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from "../../Components/ConfirmationModal";
import ToggleRadioButtons from "../../Components/ToggleRadioButtons";
import Checkbox from "../../Components/Checkbox";
import PeriodsDropdown from "../../Components/Dropdowns/PeriodsDropdown";
import AdditionalPeriodsDropdown from "./AdditionalPeriodsDropdown";
import {TotalsSearchOptionsAdvanced} from "./TotalsSearchOptionsAdvanced";
import moment from "moment-timezone";

export const STORE_TOTALS_DEFAULT_SELECTIONS = {
    percentThreshold: 25,
    dataSelection: 'Dollars',
    includeAllData: false,
    includePlu: false,
    includeIfps: false,
    productSnapshotOnly: false,
    additionalPeriods: {value: "2", label: "2"},
    shouldRenderChart: false,
    includeFillOnly: false
}

const YYYY_MM_DD_DATE_FORMAT = "YYYY-MM-DD";

function getLastWeekFromPeriod(period) {
    const weekId = Math.max(...period.weeks.map(week => week.weekId));
    const endDate = Math.max(...period.weeks.map(week => new Date(`${week.endDate}`)));
    return {weekId, endDate};
}

const TotalsSearchOptionsModal = ({
                                  cancellationCallback,
                                  confirmationCallback,
                                  onRetailerChange,
                                  retailerId,
                                  endPeriod,
                                  percentThreshold,
                                  includeAllData,
                                  includePlu,
                                  includeIfps,
                                  includeFillOnly,
                                  productSnapshotOnly,
                                  dataSelection,
                                  shouldRenderChart,
                                  stateFilterOptions,
                                  stateFilter,
                                  openStoresOnlyFilter
                                  }) => {
    const [internalPercentThreshold, updatePercentThreshold] = useState(percentThreshold === undefined ? STORE_TOTALS_DEFAULT_SELECTIONS.percentThreshold : percentThreshold);
    const [internalDataSelection, updateDataSelection] = useState(dataSelection || STORE_TOTALS_DEFAULT_SELECTIONS.dataSelection);
    const [internalIncludeAllData, updateIncludeAllData] = useState(includeAllData === undefined ? STORE_TOTALS_DEFAULT_SELECTIONS.includeAllData : includeAllData);
    const [internalIncludePlu, updateIncludePlu] = useState(includePlu === undefined ? STORE_TOTALS_DEFAULT_SELECTIONS.includePlu : includePlu);
    const [internalShouldRenderChart, updateShouldRenderChart] = useState(shouldRenderChart === undefined ? STORE_TOTALS_DEFAULT_SELECTIONS.shouldRenderChart : shouldRenderChart);
    const [internalProductSnapshotOnly, updateProductSnapshotOnly] = useState(productSnapshotOnly === undefined ? STORE_TOTALS_DEFAULT_SELECTIONS.productSnapshotOnly : productSnapshotOnly);
    const [internalRetailerId, updateInternalRetailerId] = useState(retailerId);
    const [internalIncludeFillOnly, updateInternalIncludeFillOnly] = useState(includeFillOnly === undefined ? STORE_TOTALS_DEFAULT_SELECTIONS.includeFillOnly : includeFillOnly);
    const [startPeriod, updateStartPeriod] = useState({});
    const [internalEndPeriod, updateInternalEndPeriod] = useState(endPeriod);
    const [internalEndPeriodFromAdvancedOptions, updateInternalEndPeriodFromAdvancedOptions] = useState(endPeriod);
    const [internalStateFilter, updateInternalStateFilter] = useState(stateFilter);
    const [internalOpenStoresOnlyFilter, updateInternalOpenStoresOnlyFilter] = useState(openStoresOnlyFilter)
    const [internalIncludeIfps, updateInternalIncludeIfps] = useState(includeIfps)

    return <ConfirmationModal
        modalClassName={'Modal totals-modal'}
        confirmationCallback={() => {
            onRetailerChange(internalRetailerId);
            confirmationCallback({
                percentThreshold: internalPercentThreshold,
                dataSelection: internalDataSelection,
                includeAllData: internalIncludeAllData,
                includePlu: internalIncludePlu,
                includeIfps: internalIncludeIfps,
                includeFillOnly: internalIncludeFillOnly,
                productSnapshotOnly: internalProductSnapshotOnly,
                showSearchTotalsModal: false,
                startDate: startPeriod.endDate,
                endPeriod: internalEndPeriodFromAdvancedOptions,
                endDate: internalEndPeriodFromAdvancedOptions.endDate,
                shouldRenderChart: internalShouldRenderChart,
                stateFilter: internalStateFilter,
                openStoresOnlyFilter: internalOpenStoresOnlyFilter
            })
        }}
        cancellationCallback={cancellationCallback}
        headerText={"Edit"}
    >
        <div className={'totals-modal-column-wrapper totals-modal-column-wrapper-outer'}>
            <label id={'basic-options-label'}>Basic Options</label>
            <div className={'totals-modal-row-wrapper'}>
                <div className={'totals-modal-column-wrapper'}>
                    <label id={'retailer_id_totals_label'} data-testid={'label-retailer-id'}>Retailer ID</label>
                    <input
                        data-testid={'input-retailer-id'}
                        id={'retailer_id_totals'}
                        type={'text'}
                        defaultValue={internalRetailerId}
                        onChange={(event) => updateInternalRetailerId(event.target.value)}
                    />
                </div>
                <div className={'totals-modal-column-wrapper'}>
                    <label id={'percent_threshold_label'} data-testid={'label-percent-threshold'}>Threshold Percent to Flag</label>
                    <input
                        data-testid={'input-percent-threshold'}
                        id={'percent_threshold'}
                        type={'text'}
                        defaultValue={internalPercentThreshold}
                        onChange={(event) => updatePercentThreshold(event.target.value)}
                    />
                </div>
            </div>
            <div className={'totals-modal-row-wrapper'}>
                <div className={'totals-modal-column-wrapper'} data-testid={'input-wrapper-period'}>
                    <label id={'period_label'} data-testid={'label-period'}>Select Period</label>
                    <PeriodsDropdown
                        id={'modal_period'}
                        className={"data-row-dropdown"}
                        updatePeriod={(_, period) => {
                            const {weekId, endDate} = getLastWeekFromPeriod(period);
                            updateInternalEndPeriod({...period, weekId, endDate});
                            return updateInternalEndPeriodFromAdvancedOptions(
                                {
                                    ...period,
                                    weekId,
                                    endDate: moment(endDate).tz('America/Chicago').format(YYYY_MM_DD_DATE_FORMAT)
                                });
                        }}
                        selectedPeriod={internalEndPeriod && internalEndPeriod.periodAbbreviation}
                        graphqlQuery={'{ periodList:periodDetails { weeks { weekId endDate } periodAbbreviation quadId } }'}
                        periodsFunction={(result) => result.periodList}
                        showLabel={false}
                        isDisabled={internalIncludeAllData}
                    />
                </div>
                <div className={'totals-modal-column-wrapper'}>
                    <label id={'additional_periods_label'} data-testid={'additional-periods-label'}>Additional Past Periods to Query</label>
                    <AdditionalPeriodsDropdown id={'additional_periods'} onChange={updateStartPeriod}
                                               endPeriod={internalEndPeriod}/>
                </div>

            </div>
        </div>
        <div className={'totals-modal-toggle-wrapper'}>
            <label id={'data_selection_label'}>Data Selection</label>
            <ToggleRadioButtons id={'data_selection'} options={['Dollars', 'Units']}
                                selectedOption={internalDataSelection}
                                selectedCallback={updateDataSelection}/>
            <br/>
            <label id={'filter_label'}>Filter</label>
            <div id={'check-boxes'}>
                <Checkbox
                    id={'include_all_data'}
                    labelText={'Return All Data'}
                    onChange={updateIncludeAllData}
                    checked={internalIncludeAllData}/>
                <Checkbox
                    id={'should_render_chart'}
                    labelText={'Render Chart'}
                    onChange={updateShouldRenderChart}
                    checked={internalShouldRenderChart}/>
                <Checkbox
                        id={'include_plu'}
                        labelText={'Include PLU'}
                        onChange={updateIncludePlu}
                        checked={internalIncludePlu}/>
                <Checkbox
                        id={'include_ifps'}
                        labelText={'Include Ifps'}
                        onChange={updateInternalIncludeIfps}
                        checked={internalIncludeIfps}/>
                <Checkbox
                        id={'product_snapshot_only'}
                        labelText={'Product Snapshot Only'}
                        onChange={updateProductSnapshotOnly}
                        checked={internalProductSnapshotOnly}/>

            </div>
            <br/>
            <div id={'check-boxes'}>
                <Checkbox
                    id={'include_fill_only'}
                    labelText={'Filled Data'}
                    onChange={updateInternalIncludeFillOnly}
                    checked={internalIncludeFillOnly}/>
                <Checkbox
                    id={'only_open_stores'}
                    labelText={'Only Open Stores'}
                    onChange={updateInternalOpenStoresOnlyFilter}
                    checked={internalOpenStoresOnlyFilter}/>
            </div>
        </div>
        <TotalsSearchOptionsAdvanced
            startWeekIdCallBack={(_, period) => updateStartPeriod({
                ...period,
                weekId: period.periodWeek,
                quadId: period.periodQuad
            })}
            endWeekIdCallback={(_, period) => {
                return updateInternalEndPeriodFromAdvancedOptions({
                    ...period,
                    endDate: period.endDate,
                    weekId: period.periodWeek,
                    quadId: period.periodQuad
                });
            }}
            stateFilterCallback={(stateOption) => {
                return updateInternalStateFilter(stateOption?.value)
            }}
            startWeekId={startPeriod.weekId}
            endWeekId={internalEndPeriodFromAdvancedOptions.weekId || getLastWeekFromPeriod(internalEndPeriodFromAdvancedOptions).weekId}
            isDisabled={internalIncludeAllData}
            stateFilterOptions={stateFilterOptions}
            stateFilter={internalStateFilter}
        />
    </ConfirmationModal>;
};

TotalsSearchOptionsModal.propTypes = {
    cancellationCallback: PropTypes.func.isRequired,
    confirmationCallback: PropTypes.func.isRequired,
    onRetailerChange: PropTypes.func.isRequired,
    retailerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    endPeriod: PropTypes.shape({
        periodAbbreviation: PropTypes.string,
        weekId: PropTypes.number,
        quadId: PropTypes.number
    }),
    shouldRenderChart: PropTypes.bool,
    percentThreshold: PropTypes.number,
    includeAllData: PropTypes.bool,
    includePlu: PropTypes.bool,
    includeIfps: PropTypes.bool,
    includeFillOnly: PropTypes.bool,
    productSnapshotOnly: PropTypes.bool,
    dataSelection: PropTypes.string,
    stateFilterOptions: PropTypes.array,
    stateFilter: PropTypes.object
};

export default TotalsSearchOptionsModal;