import { useEffect, useState } from "react";
import { makeApiCall, makeApiCallPost } from "../../../Api/makeApiCall";
import { getSingleRowActionQueryParams } from "./helpers";

export function useServiceBase({ endpointUrl, endpointUrlForPost, urlContainsParams }) {
    if (!endpointUrlForPost) {
        endpointUrlForPost = endpointUrl;
    }
    if (!urlContainsParams){
        urlContainsParams = false
    }
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [triggerRefresh, setTriggerRefresh] = useState(Date.now());

    useEffect(() => {
        setLoading(true);
        makeApiCall(endpointUrl).then((result) => {
            setData(result);
            setLoading(false);
        });
    }, [endpointUrl, triggerRefresh]);

    function onRowEditConfirmed(currentData) {
        return makeApiCallPost(endpointUrlForPost + getSingleRowActionQueryParams("update", urlContainsParams), currentData)
            .then(() => setTriggerRefresh(Date.now()));
    }

    function onRowDeleteConfirmed(currentData) {
        return makeApiCallPost(endpointUrlForPost + getSingleRowActionQueryParams("delete", urlContainsParams), currentData)
            .then(() => setTriggerRefresh(Date.now()));
    }
    function onDeleteAllConfirmed(currentData) {
        return makeApiCallPost(endpointUrlForPost + "deleteAllData", currentData)
            .then(() => setTriggerRefresh(Date.now()));
    }

    return {
        loading,
        setLoading,
        data,
        onRowEditConfirmed,
        onRowDeleteConfirmed,
        onDeleteAllConfirmed,
        refreshData: () => setTriggerRefresh(Date.now())
    };
}